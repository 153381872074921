<template>
  <v-card
    flat
    >
    <v-card-text
      class="pa-0"
      >
      <v-alert
        color="grey lighten-1"
        outlined
        >
        <div
          class="caption px-2 grey--text text--darken-1"
          >
          {{ (new Date(rate.createdAt)).toLocaleDateString() }}
        </div>
        <v-rating
          background-color="grey lighten-2"
          color="primary"
          empty-icon="mdi-star-outline"
          full-icon="mdi-star"
          length="5"
          readonly
          v-model="rate.rate"
          size="15"
          class="mb-3"
          ></v-rating>
        <div
          class="px-2 primary--text text--lighten-2"
          >
          {{ rate.comment }}
        </div>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    rate: {
      required: true,
      type: Object
    }
  }
}
</script>
